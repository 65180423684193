<template>
  <div style="max-width: 600px; margin: 0 auto;">
    <h1>Final Exam Antoine</h1>
    <div>
      <input
        v-model="query"
        type="text"
        placeholder="Entrer question ici..."
        @keyup.enter="sendQuery"
        style="width: 100%; padding: 10px; margin-bottom: 10px;"
      />
      <button @click="sendQuery" style="padding: 10px;">Search</button>
    </div>
    <div v-if="loading" style="margin-top: 20px;">
      <em>Chargement...</em>
    </div>
    <div v-if="error" style="margin-top: 20px; color: red;">
      {{ error }}
    </div>
    <div v-if="answerHtml" style="margin-top: 20px;">
      <!-- Use v-html to render sanitized HTML -->
      <div v-html="answerHtml" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { marked } from 'marked';
import DOMPurify from 'dompurify';

export default {
  data() {
    return {
      query: '',
      answer: null,
      loading: false,
      error: null
    }
  },
  computed: {
    answerHtml() {
      if (this.answer) {
        const rawHtml = marked(this.answer)      // Convert Markdown to HTML
        const sanitizedHtml = DOMPurify.sanitize(rawHtml) // Sanitize the HTML
        return sanitizedHtml
      }
      return null
    }
  },
  methods: {
    async sendQuery() {
      this.answer = null
      this.error = null
      this.loading = true

      try {
        const response = await axios.post('https://api.query-juriste.com/api/semantic_rag', { query: this.query }, { timeout: 180000 })
        this.answer = response.data.answer
      } catch (e) {
        this.error = 'Failed to fetch the answer. Please try again.'
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
h1 {
  text-align: center;
  margin-bottom: 20px;
}
</style>
