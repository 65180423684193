<template>
  <transition name="fade">
    <div>
      <h2 class='title-cartographie'>Cartographie de l'analyse</h2>
      <div class="graph-container" :class="{ 'with-panel': isSommairePanelVisible }" ref="graphContainer" id="graph-container">
        <!-- The graph will be rendered inside this div -->
      </div>
      <div v-if="isSommairePanelVisible" class="sommaire-panel">
        <button @click="closeSommairePanel" class="close-button">&times;</button>

        <!-- Sommaire Content -->
        <div class="sommaire-content" v-if="titlePanel && analysisContent">
          <h3><em>{{ titlePanel }}</em></h3>
          <p>
            <span v-for="(segment, idx) in analysisContent" :key="idx">
              <template v-if="segment.type === 'text'">
                {{ segment.content }}
              </template>
              <template v-else-if="segment.type === 'citation'">
                {{ segment.content }}
              </template>
              <template v-else-if="segment.type === 'bold'">
                <strong>{{ segment.content }}</strong>
              </template>
              <template v-else-if="segment.type === 'title'">
                <a
                  href="#"
                  @click.prevent="onTitleClick(segment)"
                >
                  <em><u>{{ segment.content }}</u></em>
                </a>
              </template>
              <template v-else-if="segment.type === 'url'">
                <a
                  :href="segment.content"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>URL: {{ segment.content }}</strong>
                </a>
              </template>
              <template v-else-if="segment.type === 'decision'">
                <ul>
                  <li>
                    <a href="#" @click.prevent="onDecisionSegmentClick(segment.data)">{{ segment.content }}</a>
                  </li>
                </ul>
              </template>
            </span>
          </p>
          <transition name="fade">
            <div v-if="contentToDisplay" class="source-content">
              <p>
                <span v-for="(segment, idx) in contentToDisplay" :key="idx">
                  <template v-if="segment.type === 'text'">
                    {{ segment.content }}
                  </template>
                  <template v-else-if="segment.type === 'citation'">
                    <u>{{ segment.content }}</u>
                  </template>
                  <template v-else-if="segment.type === 'bold'">
                    <strong>{{ segment.content }}</strong>
                  </template>
                  <template v-else-if="segment.type === 'title'">
                    <strong style="font-size: 1.1em">{{ segment.content }}</strong>
                  </template>
                  <template v-else-if="segment.type === 'link-decision'">
                    <a href="#" @click.prevent="openTextModal(segment.data.decisionId, segment.data.title, '')">
                      <u>{{ segment.content }}</u>
                    </a>
                  </template>
                </span>
              </p>
            </div>
          </transition>
        </div>
      </div>
      <TextModal
        ref="textModal"
        :isVisible="showTextModal"
        :title="modalTitle"
        :text="modalText"
        :citation="modalCitation"
        @close="closeTextModal"
      />
    </div>
  </transition>
</template>

<script>
import { auth, db } from "@/firebase/firebaseConfig";
import { doc, getDoc } from 'firebase/firestore';
import TextModal from './TextModal.vue';
import * as d3 from 'd3';
import { flextree } from 'd3-flextree';

export default {
  props: ['pointsList', 'dossierName'],

  components: {
    TextModal,
  },

  data() {
    return {
      contentToDisplay: '',
      activeArticleId: null,
      dossierData: null,
      modalCitation: "",
      autreAnalysisContent: "",
      activeArticleIdText: null,
      articleTextProcessed: "",
      articleSummaries: {},
      articleText: "",
      articleTitle: "",
      isTextPanelVisible: false,
      selectedSommaireContent: null,
      isSommairePanelVisible: false,
      analysisContent: [],
      titlePanel: "",
      articleAnalysisContent: null,
      titleArticle: "",
      articleId: "",
      showTextModal: false,
      modalText: "",
      modalTitle: "",
      decisionAnalysisContent: null,
      titleDecision: "",
      decisionId: "",
      showLink: true,
      visaList: [], // Initialize visaList
    };
  },

  computed: {
    uniquePoints() {
      return [...new Set(this.pointsList.map(analysis => analysis.point))];
    }
  },

  mounted() {
    this.fetchDossierData();
    console.log('dossierData:', this.dossierData)
  },

  watch: {
    pointsList(newVal) {
      if (newVal && newVal.length > 0) {
        this.createGraph();
      }
    }
  },

  methods: {
    breakContent(content) {
      return content.replace(/\n/g, '<br>');
    },

    resetPanelData() {
      console.log('Resetting panel data');
      this.contentToDisplay = ''; // Reset contentToDisplay
      this.autreAnalysisContent =  [];
      this.titlePanel = '';
      this.analysisContent = [];
      this.titleArticle = '';
      this.articleId = '';
      this.articleAnalysisContent = [];
      this.titleDecision = '';
      this.decisionId = '';
      this.decisionAnalysisContent = [];
    },


    // In your createGraph method
    createGraph() {
      const vm = this;
      const container = this.$refs.graphContainer;
      const width = container.clientWidth;
      const height = container.clientHeight;

      // Remove existing SVG if it exists
      d3.select(container).select('svg').remove();

      // Create SVG element
      const svg = d3.select(container)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .attr('viewBox', [0, 0, width, height])
        .attr('preserveAspectRatio', 'xMidYMid meet');

      // Append a group element that will contain all content and be transformed by zoom
      const g = svg.append('g');

      // Define currentTransform
      let currentTransform = d3.zoomIdentity;

      // Define desired spacing between nodes
      const desiredSpacing = 50; // Adjust as needed

      // Define the zoom behavior
      const zoomBehavior = d3.zoom()
        .scaleExtent([0.5, 2]) // Set minimum and maximum zoom scale
        .on('zoom', (event) => {
          g.attr('transform', event.transform);
          currentTransform = event.transform;
        });

      // Define the modified drag behavior
      const dragBehavior = d3.drag()
        .filter(function (event) {
          // Prevent drag from starting if the event target or its ancestors have the 'clickable' class
          let el = event.target;
          while (el) {
            if (el.classList && el.classList.contains('clickable')) {
              return false; // Do not apply drag
            }
            el = el.parentNode;
          }
          return true; // Apply drag otherwise
        })
        .on('start', dragStarted)
        .on('drag', dragged)
        .on('end', dragEnded);

      // Apply the zoom behavior to the SVG
      svg.call(zoomBehavior);

      // Create hierarchical data structure with node dimensions
      const rootData = {
        name: this.dossierName,
        nodeWidth: 0,
        nodeHeight: 0,
        children: this.dossierData.analyses_fiscales.map((pointData, pointIndex) => {
          const rectWidth = 300;
          const rectHeight = 200;

          // Create the "response" node with sources as children
          const responseNode = {
            point: pointData.point,
            final_answer: pointData.final_answer,
            name: "Réponse",
            id: `response-${pointIndex}`,
            group: 'response',
            nodeWidth: 100, // Adjust size as needed
            nodeHeight: 100, // Adjust size as needed
            children: pointData.analyses // Add sources as children of "response"
              .filter((sourceData) => sourceData && Object.keys(sourceData.analysis).length > 0)
              .map((sourceData, sourceIndex) => {
                const circleRadius = 50;
                const nodeWidth = circleRadius * 2;
                const nodeHeight = circleRadius * 2;
                return {
                  name:
                    sourceData.source === 'juritext'
                      ? 'Décisions'
                      : sourceData.source === 'legiarti'
                        ? 'Articles de lois'
                        : sourceData.source === 'bofip'
                          ? 'Bofip'
                          : 'Conventions fiscales',
                  sourceId: sourceData.source,
                  id: `${sourceData.source}-${pointIndex}-${sourceIndex}`,
                  group: sourceData.source,
                  nodeWidth: nodeWidth,
                  nodeHeight: nodeHeight,
                  data: sourceData,
                };
              }),
          };

          return {
            name: pointData.point,
            final_answer: pointData.final_answer,
            id: `point-${pointIndex}`,
            group: 'point',
            nodeWidth: rectWidth,
            nodeHeight: rectHeight,
            children: [responseNode], // Add the responseNode as a child of the point
          };
        }),
      };

      // Create a hierarchical layout
      const root = d3.hierarchy(rootData, (d) => d.children);

      // Use d3-flextree for variable node sizes
      const treeLayout = flextree().nodeSize((node) => [
        node.data.nodeWidth + desiredSpacing,
        node.data.nodeHeight + desiredSpacing,
      ]);

      treeLayout(root);

      // Center the tree in the SVG
      const nodes = root.descendants();
      const xMin = d3.min(nodes, (d) => d.x - d.data.nodeWidth / 2);
      const xMax = d3.max(nodes, (d) => d.x + d.data.nodeWidth / 2);
      const yMin = d3.min(nodes, (d) => d.y - d.data.nodeHeight / 2);
      const yMax = d3.max(nodes, (d) => d.y + d.data.nodeHeight / 2);

      const padding = 50; // Adjust padding as needed

      const maxInitialScale = 1; // Maximum initial scale to prevent over-zooming in
      const minInitialScale = 0.5; // Minimum initial scale to prevent over-zooming out

      const widthScale = (width - 2 * padding) / (xMax - xMin || 1);
      const heightScale = (height - 2 * padding) / (yMax - yMin || 1);
      let scale = Math.min(widthScale, heightScale);

      // Clamp the initial scale within the desired limits
      scale = Math.max(minInitialScale, Math.min(maxInitialScale, scale));

      const translateX = width / 2 - ((xMin + xMax) / 2) * scale;
      const translateY = padding - yMin * scale;

      // Set initial transform
      currentTransform = d3.zoomIdentity.translate(translateX, translateY).scale(scale);
      g.attr('transform', currentTransform);

      // Apply initial transform to zoom behavior
      svg.call(zoomBehavior.transform, currentTransform);

      // Create links (edges)
      const link = g
        .selectAll('.link')
        .data(root.links())
        .enter()
        .append('path')
        .attr('class', 'link')
        .attr('fill', 'none')
        .attr('stroke', '#999')
        .attr('stroke-opacity', 0.6)
        .attr('stroke-width', 2)
        .attr(
          'd',
          d3
            .linkVertical()
            .x((d) => d.x)
            .y((d) => d.y)
        );

      // Create nodes
      const nodeSelection = g
        .selectAll('.node')
        .data(root.descendants())
        .enter()
        .append('g')
        .attr('class', 'node')
        .attr('transform', (d) => `translate(${d.x},${d.y})`);

      // Apply the drag behavior to nodes
      nodeSelection.call(dragBehavior);

      // Append elements based on node group
      nodeSelection.each(function (d) {
        if (d.depth === 0) {
          // Skip rendering for the dummy root
        } else if (d.data.group === 'point') {
          // Use node dimensions from data
          const rectWidth = d.data.nodeWidth;
          const rectHeight = d.data.nodeHeight;

          // Append a rectangle
          d3.select(this)
            .append('rect')
            .attr('x', -rectWidth / 2) // Center horizontally
            .attr('y', -rectHeight / 2) // Center vertically
            .attr('width', rectWidth)
            .attr('height', rectHeight)
            .attr('fill', '#3498db') // Rectangle color
            .attr('rx', 10) // Rounded corners (optional)
            .attr('ry', 10) // Rounded corners (optional)
            .attr('class', 'clickable')
            .on('mouseover', function () {
              d3.select(this)
                .transition()
                .duration(100)
                .attr('fill', '#2980b9') // Change color on hover
                .style('transform', 'scale(1.02)') // Slight scaling
                .style('filter', 'drop-shadow(0 4px 6px rgba(0,0,0,0.3))');
            })
            .on('mouseout', function () {
              d3.select(this)
                .transition()
                .duration(300)
                .attr('fill', '#3498db') // Revert color
                .style('transform', 'scale(1)') // Revert scaling
                .style('filter', 'none'); // Remove shadow
            })
            .on('click', function (event, d) {
              vm.onPointClick(d);
            });

          // Append text inside the rectangle
          const textGroup = d3
            .select(this)
            .append('text')
            .attr('text-anchor', 'middle')
            .style('font-family', 'Roboto')
            .attr('dominant-baseline', 'middle')
            .attr('x', 0)
            .attr('y', 0)
            .attr('fill', '#fff')
            .style('font-size', '16px')
            .style('font-weight', 'light')
            .attr('class', 'clickable')
            .on('mouseover', function () {
              d3.select(this)
                .transition()
                .duration(100)
                .attr('fill', '#2980b9')
                .style('transform', 'scale(1.02)')
                .style('filter', 'drop-shadow(0 4px 6px rgba(0,0,0,0.3))');
            })
            .on('mouseout', function () {
              d3.select(this)
                .transition()
                .duration(300)
                .attr('fill', '#fff')
                .style('transform', 'scale(1)')
                .style('filter', 'none');
            })
            .on('click', function (event, d) {
              vm.onPointClick(d);
            });

          // Wrap text into lines
          const words = d.data.name.split(/\s+/);
          const lineHeight = 20;
          const lines = [];
          let currentLine = [];

          words.forEach((word) => {
            const testLine = [...currentLine, word].join(' ');
            const testElement = textGroup.append('tspan').text(testLine);
            const testWidth = testElement.node().getComputedTextLength();
            testElement.remove();

            if (testWidth > rectWidth - 20) {
              lines.push(currentLine.join(' '));
              currentLine = [word];
            } else {
              currentLine.push(word);
            }
          });

          if (currentLine.length) {
            lines.push(currentLine.join(' '));
          }

          lines.forEach((line, i) => {
            textGroup
              .append('tspan')
              .text(line)
              .attr('x', 0)
              .attr('dy', i === 0 ? -((lines.length - 1) * lineHeight) / 2 : lineHeight);
          });
        } else {
          // Use node dimensions from data for circles
          const circleRadius = d.data.nodeWidth / 2;

          d3.select(this)
            .append('circle')
            .attr('r', circleRadius)
            .attr('r', d.data.name === 'Réponse' ? circleRadius*1.2 : circleRadius)
            .attr('class', 'clickable')
            .attr('fill', d.data.name === 'Réponse' ? '#3498db' : '#1f77b4')
            .on('mouseover', function () {
              d3.select(this)
                .transition()
                .duration(100)
                .attr('fill', '#2980b9')
                .style('transform', 'scale(1.02)')
                .style('filter', 'drop-shadow(0 4px 6px rgba(0,0,0,0.3))');
            })
            .on('mouseout', function () {
              d3.select(this)
                .transition()
                .duration(300)
                .attr('fill', d.data.name === 'Réponse' ? '#3498db' : '#1f77b4')
                .style('transform', 'scale(1)')
                .style('filter', 'none');
            })
            .on('click', function (event, d) {
                if (d.data.name === 'Réponse') {
                    vm.onReponseClick(d);
                } else {
                    vm.onSourceClick(d);
                }
            });

          // Add text inside the circle
          const textElement = d3
            .select(this)
            .append('text')
            .attr('fill', '#fff')
            .attr('class', 'clickable')
            .attr('text-anchor', 'middle')
            .style('font-family', 'Roboto')
            .style('font-weight', 300)
            .on('mouseover', function () {
              d3.select(this)
                .transition()
                .duration(100)
                .attr('fill', '#2980b9')
                .style('transform', 'scale(1.02)')
                .style('filter', 'drop-shadow(0 4px 6px rgba(0,0,0,0.3))');
            })
            .on('mouseout', function () {
              d3.select(this)
                .transition()
                .duration(300)
                .attr('fill', '#fff')
                .style('transform', 'scale(1)')
                .style('filter', 'none');
            })
            .on('click', function (event, d) {
                if (d.data.name === 'Réponse') {
                    vm.onReponseClick(d);
                } else {
                    vm.onSourceClick(d);
                }
            });

          // Adjust font size and text wrapping
          let fontSize = 18;
          textElement.style('font-size', fontSize + 'px');

          const maxTextWidth = circleRadius * 2 * 0.8;
          const maxTextHeight = circleRadius * 2 * 0.8;

          let lineCount;
          let textHeight;

          do {
            textElement.text('');
            textElement.style('font-size', fontSize + 'px');

            lineCount = wrapText(textElement, d.data.name, maxTextWidth);

            const bbox = textElement.node().getBBox();
            textHeight = bbox.height;

            if (textHeight > maxTextHeight) {
              fontSize -= 1;
            }
          } while (textHeight > maxTextHeight && fontSize > 6);

          const totalLineHeight = lineCount * fontSize * 1.1;
          const yOffset = -totalLineHeight / 2 + fontSize / 2;

          textElement.selectAll('tspan').attr('y', (_, i) => yOffset + i * fontSize * 1.1);
        }
      });

      // Define drag event handlers
      function dragStarted() {
        d3.select(this).raise().attr('stroke', 'black');
      }

      function dragged(event, d) {
        const dx = event.dx / currentTransform.k;
        const dy = event.dy / currentTransform.k;

        d.x += dx;
        d.y += dy;

        d3.select(this).attr('transform', `translate(${d.x},${d.y})`);

        // Update connected links
        link.attr(
          'd',
          d3
            .linkVertical()
            .x((d) => d.x)
            .y((d) => d.y)
        );
      }

      function dragEnded() {
        d3.select(this).attr('stroke', null);
      }

      function wrapText(textElement, text, maxWidth) {
        textElement.text('');

        const words = text.split(/\s+/);
        let line = [];
        let lineNumber = 0;
        const lineHeight = 1.1;
        let tspan = textElement.append('tspan').attr('x', 0).attr('dy', 0);

        for (let i = 0; i < words.length; i++) {
          line.push(words[i]);
          tspan.text(line.join(' '));
          const textLength = tspan.node().getComputedTextLength();

          if (textLength > maxWidth && line.length > 1) {
            line.pop();
            tspan.text(line.join(' '));
            line = [words[i]];
            tspan = textElement
              .append('tspan')
              .attr('x', 0)
              .attr('dy', `${lineHeight}em`)
              .text(words[i]);
            lineNumber++;
          }
        }

        return lineNumber + 1;
      }
    },

    onPointClick(nodeData) {
      // Handle click on point node
      console.log('Point clicked:', nodeData);
      this.resetPanelData();
      this.titlePanel = nodeData.data.name;
      // For now, set analysisContent to empty or some placeholder
      this.analysisContent = this.parseContent(nodeData.data.final_answer); // or fetch content
      this.isSommairePanelVisible = true;
    },

    onReponseClick(nodeData) {
      console.log('Reponse clicked:', nodeData);
      this.resetPanelData();
      this.titlePanel = nodeData.data.point;
      this.analysisContent = this.parseContent(nodeData.data.final_answer); // or fetch content
      this.isSommairePanelVisible = true;
    },

    onSourceClick(nodeData) {
      console.log('Source clicked:', nodeData);
      this.resetPanelData();
      this.titlePanel = nodeData.data.name;
      this.isSommairePanelVisible = true;

      // Get the list of documents
      const documents = nodeData.data.data.analysis;
      // Prepare analysisContent
      this.analysisContent = [];

      documents.forEach((doc) => {
        if (nodeData.data.sourceId === 'juritext') {
          // Handle juritext source
          const titre = doc.title;
          // Each document has title and summary
          this.analysisContent.push({
            type: 'title',
            content: titre + '\n\n',
            data: {
              content: doc.summary,  // Assuming doc.summary contains the summary of the decision
              decisionId: doc.id
            }
          });
        } else if (nodeData.data.sourceId === 'legiarti') {
          console.log('Legiarti doc:', doc.hierarchy);
          // Handle legiarti source
          const titre = doc.textNumber;
          const hierarchyAndContent = doc.hierarchy + '\n\n' + doc.content;
          this.analysisContent.push({
            type: 'title',
            content: titre + '\n\n',
            data: {
              content: hierarchyAndContent
            }
          });
        } else if (nodeData.data.sourceId === 'bofip') {
          // Handle bofip source
          const titre = doc.title;
          this.analysisContent.push({
            type: 'title',
            content: titre + '\n',
            data: {
              content: doc.content
            }
          });
          this.analysisContent.push({
            type: 'url',
            content: doc.url + '\n\n',
            data: {
              content: doc.content
            }
          });
        } else {
          // Handle convbi source or any other fallback
          const titre = doc.title;
          this.analysisContent.push({
            type: 'title',
            content: titre + '\n',
            data: {
              content: doc.content
            }
          });
          this.analysisContent.push({
            type: 'url',
            content: doc.url + '\n\n',
            data: {
              content: doc.content
            }
          });
        }
      });
    },



    async fetchDossierData() {
      const userId = auth.currentUser.uid;
      const dossierId = this.$route.params.dossierId;
      const dossierDocRef = doc(db, 'users', userId, 'dossiers', dossierId);
      try {
        const docSnap = await getDoc(dossierDocRef);
        if (docSnap.exists()) {
          console.log("Dossier data:", docSnap.data());
          this.dossierData = docSnap.data();  // Ensure this matches your Firestore field name
          this.createGraph(); // Recreate the graph with the fetched visaList
        } else {
          console.error("No such dossier!");
        }
      } catch (error) {
        console.error("Error getting dossier document:", error);
      }
    },

    onTitleClick(segment) {
      // Parse the content using your existing parseContent function
      const parsedContent = this.parseContent(segment.data.content);
      console.log('Parsed content:', segment);
      // Prepend the 'link-decision' segment to the parsed content
      if (segment.data.decisionId) {
        parsedContent.unshift({
          type: 'link-decision',
          content: '\nVoir décision complète\n',
          data: {
            decisionId: segment.data.decisionId,
            title: segment.content.trim(),
          },
        });
      }

      // Set the contentToDisplay to the new array
      this.contentToDisplay = parsedContent;
    },

    async openTextModal(decisionId, title, segment) {
      const docRef = doc(db, 'cases', decisionId);
      const docSnap = await getDoc(docRef);
      this.showTextModal = true;
      if (docSnap.data().texte) {
        this.modalText = docSnap.data().texte;
      } else {
        this.modalText = 'Texte non disponible.';
      }
      this.modalTitle = title;
      this.modalCitation = segment;

      this.$nextTick(() => {
        if (this.$refs.textModal) {
          this.$refs.textModal.highlightCitation();
        }
      });
    },

    closeTextModal() {
      this.showTextModal = false;
    },

    closeSommairePanel() {
      this.isSommairePanelVisible = false;
      this.selectedSommaireContent = null;
      this.articleAnalysisContent = null;
      this.contentToDisplay = ''; // Reset contentToDisplay
      this.decisionAnalysisContent = null; // Reset decision content
      this.titleDecision = ""; // Reset decision title
      this.decisionId = ""; // Reset decision ID
      // If using unified panel properties
      // this.currentPanelContent = null;
      // this.currentPanelTitle = null;
      // this.currentContentType = null;
    },

    parseContent(text) {
      const regex = /"([^"]*)"|\*\*([^*]+)\*\*|\*([^*]+)\*|«([^»]+)»|(?:^|\s)(#+\s)([^\n]*)/g;
      const segments = [];
      let lastIndex = 0;
      let match;

      while ((match = regex.exec(text)) !== null) {
        // Add plain text before the match
        if (match.index > lastIndex) {
          segments.push({
            type: 'text',
            content: text.slice(lastIndex, match.index)
          });
        }

        // Handle each regex group type
        if (match[1]) { // Double quotes
          segments.push({
            type: 'citation',
            content: '«' + match[1] + '»'
          });
        } else if (match[2]) { // Bold with **
          segments.push({
            type: 'bold',
            content: match[2]
          });
        } else if (match[3]) { // Italic with *
          segments.push({
            type: 'italic',
            content: match[3]
          });
        } else if (match[4]) { // French quotation marks « »
          segments.push({
            type: 'citation',
            content: '«' + match[4] + '»'
          });
        } else if (match[5] && match[6]) { // Titles with # pattern
          segments.push({
            type: 'title',
            content: match[6]
          });
        }

        // Update lastIndex
        lastIndex = regex.lastIndex;
      }

      // Add remaining text after the last match
      if (lastIndex < text.length) {
        segments.push({
          type: 'text',
          content: text.slice(lastIndex)
        });
      }

      return segments;
    },

  }
};
</script>

<style scoped>

#decision-title-panel {
  margin-bottom: 5px;
}

.decision-link {
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

#decision-analysis {
  margin-top: 50px;
  border-top: #333;
}

.title-cartographie {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.fade-enter-active{
  transition: clip-path 1s ease-out, opacity 1s;
  clip-path: inset(0 0 0 0); /* Ends with the full element visible */
  opacity: 1;
}

.fade-enter {
  opacity: 0;
  clip-path: inset(0 0 100% 0); /* Start with the element fully clipped at the bottom */
}

.fade-leave-active {
  transition: clip-path 0.8s ease-out, opacity 0.8s;
  clip-path: inset(0 0 0 0); /* Ends with the full element visible */
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
  clip-path: inset(100% 0 0 0); /* Start with the element fully clipped at the bottom */
}

.point-title {
  font-size: 1.7rem;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.sommaire-columns {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.sommaire-section {
  flex: 1 1 45%; /* Adjust the percentage as needed */
  border-radius: 15px;
  padding: 7px;
  margin: 10px;
  box-sizing: border-box;
}

.sommaire-section h4 {
  font-size: 1.1em;
  margin-bottom: 5px;
}

.sommaire-section p {
  font-size: 1em;
  line-height: 1.4;
  text-align: justify;
}

@media (max-width: 600px) {
  .sommaire-section {
    flex: 1 1 100%;
  }
}


.summary {
  margin-bottom: 20px;
  height: 100%;
}

.summary ul {
  list-style: none;
  padding: 0;
}

.summary li {
  display: inline-block;
  margin: 0 10px;
}

.summary a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.summary a:hover {
  text-decoration: underline;
}

.text-panel {
  background-color: #f5efeb;
  overflow-y: auto;
  overflow-x: hidden;
  position:relative;
  width: 50%;
  display: block;
  padding: 20px;
  border: 1px solid #ccc;
  max-height: 100vh;
}

.texte-pertinence {
  margin: 15px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
}

.half-width {
  width: 50%!important;
}

.case-text {
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 0.8em;
  font-family: 'Roboto', sans-serif;
}

.citation-highlight {
  background-color: yellow;
  display: inline-block;
}

.card-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#modal-content-id {
  background-color: white;
  width: 82%;
  padding: 30px;
  border-radius: 25px;
  overflow-x: hidden;
  position: relative;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;
}

.close-button {
  float: right;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor:pointer;
  position: absolute;
  top: 25px;
  right: 25px;
}

h2, h3 {
  margin-top: 0;
  text-align: center;
  color: #333;
}

ul {
  list-style: none;
  padding: 0;
}

#autre-analysis {
  border-top: 0px solid #ccc !important;
}

li {
  margin-bottom: 15px;
  padding: 10px;
  border-bottom: 1px solid #eee;
}

li:last-child {
  border-bottom: none;
}

.summary ul ul {
  margin-left: 20px;
  list-style-type: disc;
}

.summary ul ul li {
  display: block; /* Makes each item take the full width */
  margin: 5px 0;
  white-space: nowrap; /* Prevents the text from wrapping */
  overflow: hidden; /* Keeps the text within the bounds of the li element */
  text-overflow: ellipsis; /* Adds ellipsis if the text overflows */
}

.summary ul ul li a {
  font-weight: normal;
  color: #555;
  font-size: 0.9rem;
}

.summary ul ul li a:hover {
  text-decoration: underline;
}

#graph-container {
  width: 95%;
  height: 90vh;
  margin: 0 auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5%;
}

.links line {
  stroke: #999;
  stroke-opacity: 0.6;
}

.nodes circle {
  stroke: #fff;
  stroke-width: 1.5px;
}

.labels text {
  pointer-events: none;
  user-select: none;
}

.graph-container.with-panel {
  width: 60%; /* Adjust width when panel is visible */
}

.sommaire-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background-color: #f5efeb;
  border-left: 1px solid #ccc;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  z-index: 1000;
}

.sommaire-content {
  margin-top: 30px;
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  font-size: 1em;
  font-family: 'Roboto', sans-serif;
}


.sommaire-content h3 {
  margin-bottom: 25px;
  font-size: 1.25rem;
  margin-top: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
  border: none;
  background: none;
}

.clickable {
  cursor: pointer; /* Changes cursor to pointer on hover */
  pointer-events: all; /* Ensures the element can receive pointer events */
}

</style>
