<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <h3 class="modal-title">Supprimer le dossier</h3>
      <p class="modal-message">Êtes-vous sûr de vouloir supprimer le dossier "<strong>{{ dossierName }}</strong>" ? Cette action est irréversible.</p>
      <div class="actions">
        <button class="cancel-button" @click="$emit('cancel')">Annuler</button>
        <button class="confirm-button" @click="$emit('confirm')">Oui, supprimer</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteConfirmationModal',
  props: {
    dossierName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: center;
  position: relative;
}

.modal-title {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1em;
  color: #1f2937;
  font-size: 1.5rem;
}

.modal-message {
  margin-bottom: 2em;
  font-size: 1rem;
  color: #333;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.cancel-button,
.confirm-button {
  width: 45%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button {
  background-color: #ccc;
  color: #333;
}

.cancel-button:hover {
  background-color: #bbb;
}

.confirm-button {
  background-color: #d9534f; /* Bootstrap's danger color */
  color: white;
}

.confirm-button:hover {
  background-color: #c9302c;
}
</style>
