<template>
  <div v-if="isVisible" class="modal-overlay">
    <div class="modal-content" ref="modalContent">
      <button @click="$emit('close')" class="close-button" style="margin-top: 5px;">×</button>
      <!-- Left Content -->
      <div class="left-content">
        <h2 style="font-size: 1.5rem; font-weight: 300; margin-bottom: 25px;">
          Synthèse Juridique Assistée
        </h2>
        <div class="textarea-content">
          <textarea
            v-model="localContext"
            rows="5"
            cols="50"
            class="search-input bg-light"
          ></textarea>
        </div>

        <div v-if="isLoadingPoints" class="loading-message">
          <!-- Show loading message or spinner -->
          <div class="title-points">
            <h4 style="font-size: 1.2rem; font-weight: 300">
              <em>Identification des points de droits...</em>
            </h4>
          </div>
        </div>

        <div v-else-if="pointsList.length === 0">
          <!-- Show the button to fetch points -->
          <div class="buttons">
            <button @click="fetchPoints" class="enrichir-button">
              Lancer la synthèse
            </button>
            <button @click="$emit('close')" class="back-button">
              Annuler
            </button>
          </div>
        </div>

        <transition name="fade">
          <div v-if="pointsList.length !== 0" class="points-class">
            <!-- Display the points with editing capabilities -->
            <div class="title-points">
              <h4 style="font-size: 1.2rem; font-weight: 300">
                <em>
                  Notre système effectuera une recherche sur chacun de ces
                  points
                </em>
              </h4>
            </div>
            <ul class="points-list">
              <li
                v-for="(point, index) in pointsList"
                :key="point.id"
                class="point-item"
              >
                <!-- Point Display and Editing -->
                <div v-if="point.isEditing" class="point-display">
                  <textarea
                    v-focus="point.isEditing"
                    v-model="point.text"
                    rows="2"
                    cols="50"
                    class="point-input bg-light"
                  ></textarea>
                  <button @click="savePoint(index)" class="small-button">
                    <!-- Save icon SVG -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                      />
                      <path
                        d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"
                      />
                    </svg>
                  </button>
                </div>
                <div v-else class="point-display">
                  <div class="point-text bg-light">{{ point.text }}</div>
                  <button @click="editPoint(index)" class="small-button">
                    <!-- Edit icon SVG -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"
                      />
                    </svg>
                  </button>
                  <button @click="deletePoint(index)" class="small-button">
                    <!-- Delete icon SVG -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash rounded-1"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"
                      />
                      <path
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"
                      />
                    </svg>
                  </button>
                </div>

                <!-- Sources Associated with the Point -->
                <ul class="sources-list">
                  <li style="font-size: 0.7rem; font-weight: 300; margin-top: 4px;">Sources:</li>
                  <li
                    v-for="(source, sIndex) in point.sources"
                    :key="sIndex"
                    class="source-item"
                  >
                    {{ source }}
                  </li>
                </ul>
              </li>
            </ul>

            <!-- Button to add a new point -->
            <div class="add-point-button">
              <button @click="addPoint" class="small-button" style="display: flex">
                <!-- Plus icon SVG -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-plus-circle"
                  viewBox="0 0 16 16"
                  style="margin-top: 4px; margin-right: 3px;"
                >
                  <path
                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                  />
                  <path
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
                  />
                </svg>
                Ajouter un point
              </button>
            </div>

            <!-- Buttons to start analysis or cancel -->
            <div class="buttons">
              <button
                @click="startAnalysis"
                class="enrichir-button"
                :disabled="pointsList.length === 0"
                :class="{ 'button-disabled': pointsList.length === 0 }"
              >
                Démarrer l'analyse
              </button>
              <button @click="$emit('close')" class="back-button">
                Annuler
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getFirebaseToken } from '@/services/api';
import { auth } from "@/firebase/firebaseConfig";

export default {
  name: 'SyntheseModal',
  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      }
    }
  },

  props: ['isVisible', 'context', 'dossierId'],
  data() {
    return {
      localContext: this.context,
      pointsList: [],
      isLoadingPoints: false,
      pointIdCounter: 0,
    };
  },

  watch: {
    context(newVal) {
      this.localContext = newVal; // Update localContext when context prop changes
    },
    'pointsList.length': function (newLength, oldLength) {
      if (oldLength === 0 && newLength > 0) {
        this.$nextTick(() => {
          const modalContent = this.$refs.modalContent;
          const h4Element = this.$el.querySelector('.points-class .title-points h4');
          if (h4Element && modalContent) {
            const h4Rect = h4Element.getBoundingClientRect();
            const modalRect = modalContent.getBoundingClientRect();
            const scrollTop = modalContent.scrollTop + (h4Rect.top - modalRect.top);
            modalContent.scrollTo({ top: scrollTop, behavior: 'smooth' });
          }
        });
      }
    },
  },

  methods: {
    fetchPoints() {
      if (!this.localContext.trim()) {
        alert('Le contexte est vide.');
        return;
      }
      this.isLoadingPoints = true;
      getFirebaseToken().then(token => {
        if (!token) {
          alert('Erreur d\'authentification. Veuillez réessayer.');
          this.isLoadingPoints = false;
          return;
        }

        axios.post(`https://api.query-juriste.com/copilot/get_points_fiscale`, {
          contexte: this.localContext,
          userId: auth.currentUser.uid,
          dossierId: this.dossierId || this.$route.params.dossierId,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          console.log(response.data);
          // Map the received points to include isEditing property
          this.pointsList = (response.data.points || []).map(point => ({
            text: point.problem,
            originalText: point.problem,
            sources: point.source.split(',').map(s => s.trim()),
            isEditing: false,
            id: this.pointIdCounter++,
          }));
          console.log('Points fetched:', this.pointsList);
          this.isLoadingPoints = false;

          // Scroll to bottom after points are loaded
          this.$nextTick(() => {
            this.scrollToBottom();
          });
        }).catch(error => {
          console.error('Error fetching points:', error);
          alert('Erreur lors de la récupération des points.');
          this.isLoadingPoints = false;
        });

      }).catch(error => {
        console.error('Error fetching token:', error);
        alert('Erreur lors de la récupération du token. Veuillez réessayer.');
        this.isLoadingPoints = false;
      });
    },

    deletePoint(index) {
      this.pointsList.splice(index, 1);
    },

    savePoint(index) {
      const point = this.pointsList[index];
      if (point.text.trim() === '') {
        alert('Le point ne peut pas être vide.');
        return;
      }
      point.isEditing = false;
    },

    startAnalysis() {
      if (this.pointsList.length === 0) {
        alert('Aucun point n\'a été sélectionné.');
        return;
      }
      // Extract the text from each point object
      // const pointsTexts = this.pointsList.map(point => point.text);
      // Emit an event to the parent component with context and pointsList
      this.$emit('startAnalysis', this.localContext, this.pointsList);
      this.$emit('close'); // Close the modal
    },
    addPoint() {
      this.pointsList.push({
        text: '',
        originalText: '',
        isEditing: true,
        id: this.pointIdCounter++,
      });
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },

    scrollToBottom() {
      const modalContent = this.$refs.modalContent;
      if (modalContent) {
        modalContent.scrollTop = modalContent.scrollHeight;
      }
    },

    editPoint(index) {
      this.pointsList[index].isEditing = true;
    },
  },
};
</script>

<style scoped>
.modal-content {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0px;
  border-radius: 8px;
  width: 90%;
  height: 90vh;
  overflow: hidden;
}

.left-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.textarea-content {
  height: 20vh;
}

.search-input {
  width: 100%;
  height: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.enrichir-button {
  background-color: #1c1c1a;
  color: #f4f4f4;
  border: none;
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
}
.back-button {
  background-color: #444;
  color: #f4f4f4;
  border: none;
  padding: 9px 15px;
  border-radius: 4px;
  font-size: 0.9rem;
  cursor: pointer;
}

.small-button {
  margin-left: 5px;
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.small-button:hover {
  background-color: #e0e0e0;
}
.point-item {
  margin-bottom: 2em;
}

.points-list {
  list-style-type: none;
  padding: 0;
}

.point-input {
  width: 80%;
  padding: 5px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.point-text {
  padding: 5px;
  border-radius: 16px;
  font-size: 1.1rem;
  background-color: #f8f9fa;
  margin-right: 10px;
  flex: 1;
}

.point-display {
  display: flex;
  align-items: center;
  flex: 1;
}

/* New styling for the add point button */
.add-point-button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.add-visa-button {
  font-size: 0.7rem;
  display: flex;
  justify-content: flex-start;
  padding: 6px 10px;
}

.add-point-button .enrichir-button {
  padding: 8px 12px;
}

.button-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.loading-message {
  color: transparent;
  background: linear-gradient(to left, lightgray 50%, black 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  animation: slide 3s linear infinite;
  cursor: default;
  pointer-events: none;
}

@keyframes slide {
  to {
    background-position: -100%;
  }
}

.points-class {
  margin-top: 20px;
}

.title-points {
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.sources-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.source-item {
  margin: 0;
  padding: 5px 5px; /* Kept as in your original code */
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  font-size: 0.6rem; /* Kept as in your original code */
  border-radius: 15px;
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: -10px; /* Position above the header content */
  right: 10px; /* Adjust distance from the right */
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

</style>
