<!-- CreateDossierModal.vue -->
<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <button class="close-button" @click="$emit('close')">&times;</button>
      <h3 class="modal-title">Créer un nouveau dossier</h3>
      <div class="input-container">
        <label for="new-dossier-name">Nom du nouveau dossier:</label>
        <input v-model="newDossierName" id="new-dossier-name" placeholder="Entrez le nom du dossier">
      </div>
      <div class="actions">
        <button class="create-button" @click="createDossier">Créer</button>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, db } from "@/firebase/firebaseConfig";
import { collection, addDoc } from 'firebase/firestore';

export default {
  name: 'CreateDossierModal',
  data() {
    return {
      newDossierName: '',
    };
  },
  methods: {
    async createDossier() {
      if (!this.newDossierName) {
        alert("Veuillez entrer un nom pour le nouveau dossier.");
        return;
      }
      try {
        const userId = auth.currentUser.uid;
        const dossiersRef = collection(db, 'users', userId, 'dossiers');
        const docRef = await addDoc(dossiersRef, { name: this.newDossierName });
        // Emit the new dossier to the parent component
        this.$emit('dossierCreated', { id: docRef.id, name: this.newDossierName });
        // Close the modal
        this.$emit('close');
      } catch (error) {
        console.error('Error creating dossier:', error);
        alert('Erreur lors de la création du nouveau dossier.');
      }
    },
  },
};
</script>

<style scoped>
/* Modal styles (same as in SaveCaseModal.vue) */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Darker semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.modal-content {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  width: 450px;
  max-width: 95%;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #555;
}

.modal-title {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1.5em;
  color: #1f2937;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 1.3rem;
}

.input-container {
  margin-bottom: 20px;
}

.input-container input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.actions {
  display: flex;
  justify-content: center;
}

.create-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.create-button:hover {
  background-color: #666;
}
</style>
