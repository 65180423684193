<template>
  <div>
    <div class="hero-section">
      <header>
        <div class="logo">
          <img src="@/assets/w-q-white.png" alt="logo" style="width: 110px; height: 90px; margin-left: 15px;">
        </div>
        <button @click="goToLogin" class="signup-button">Se connecter</button>
      </header>
      <div class="content">
        <div class='text-recherche'>
          <h1>Découvrez le futur de la</h1>
          <h1><span class="highlight">recherche juridique</span></h1>
        </div>
        <p class="p-class">Plus précis. Plus rapide. Plus performant.</p>
        <div class="buttons">
          <button @click="goToLogin" class="primary-button">Commencer maintenant</button>
        </div>
        <video controls preload="none" width="640" height="360" autoplay muted>
          <source src="https://publicstoragequery.blob.core.windows.net/demo-container/movie-low-quality.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
    </div>

    <!-- Features Overview -->
    <section id="features" class="features-section">
      <div class="features-container">
        <h3 id='h2-title'>Query Juriste</h3>
        <h2><em>L'intelligence artificelle au service des juristes de demain</em></h2>
        <div class="features">
          <div class="feature">
            <h3>Un moteur de recherche dopé à l'IA</h3>
            <p>Notre moteur de recherche innovant vous permet de poser des questions en langage naturel, des mots-clés, se charge de comprendre vos requêtes pour trouver les décisions juridiques les plus pertinentes, soigneusement synthétisées pour vous offrir un aperçu clair et concis des informations clés.</p>
          </div>
          <div class="feature">
            <h3>Notre technologie</h3>
            <p>Notre technologie repose sur l’intelligence artificielle avancée et le traitement du langage naturel, capturant le contexte de votre questions, mots-clés, et dossier pour offrir des résultats précis, pertinents et synthétisés.</p>
          </div>
          <div class="feature">
            <h3>Copilote, votre agent de recherche intelligent</h3>
            <p>Copilote vous permet non seulement de trouver les informations utiles, mais s'occupe aussi de les synthétiser et de les présenter de manière visuelle et graphique, redefinissant ainsi les standards actuels et apportant une dimension unique à l'accès à l'information juridique.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Footer -->
    <footer class="footer">
      <p>© 2024 Query Juriste, tous droits réservés - <router-link to="/mentionsPage">Mentions Légales</router-link></p>
      <p><router-link to="/conditionsGeneralePage">Conditions générales de ventes</router-link></p>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'RootPage',
  methods: {
    goToLogin() {
      this.$router.replace({ name: 'signin' });
    },
    goToTest() {
      this.$router.replace({ name: 'test' });
    }
  }
};
</script>

<style scoped>

.video-section {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center vertically */
    height: 100vh; /* Take full viewport height */
    background-color: #403250; /* Optional: in case you want a specific background */
}

.copilot-title {
  color: #c0bdd0; /* Center vertically */
  font-family: 'Roboto', sans-serif;
  font-weight: lighter;
  font-size: 2rem;
  margin-bottom: 3rem;
}

video {
  margin-top: 5em;
  width: 70%; /* Adjust the width to be larger but responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 20px;
}

.hero-section {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 160vh;
  background: linear-gradient(to bottom, #000000, 60%, #403250);
  color: #fff;
  text-align: center;
}

header {
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: space-between;
}

.signup-button {
  margin-right: 25px;
  margin-top: 25px;
  background: #7e5cf2;
  color: #D5D5D5;
  height: 3em;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.signup-button:hover {
  background: #6941c6;
  color: #ffffff;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.p-class {
  color: #c0bdd0;
  font-weight: 325;
}

.text-recherche {
  margin-bottom: 40px; /* Adjust the value as needed */
}

.text-recherche h1 {
  font-size: 2.5rem;
  margin-bottom: 0; /* Removes bottom margin of the first h1 */
  margin-top: 0; /* Removes top margin of the second h1 */
  font-weight: 340;
}

.text-recherche h1 + h1 {
  margin-top: 0; /* Ensures there's no margin-top for the second h1 */
}

.highlight {
  color: #7e5cf2;
}

p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.buttons {
  display: flex;
  gap: 20px;
}

.primary-button {
  background: #7e5cf2;
  font-weight: 570;
  color: #D5D5D5;
  border: none;
  height: 3em;
  padding: 10px 20px; /* Adjusted for smaller size */
  font-size: 14px; /* Adjusted for thinner text */
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s ease, color 0.3s ease;
}

.primary-button:hover {
  background: #6941c6;
  color: #ffffff;
}

.features-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #403250; /* Optional: Add a background color for distinction */
  padding: 2rem;
  height: 100vh;
}

.exemple-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #403250; /* Optional: Add a background color for distinction */
  color: #c0bdd0;
  font-weight: lighter;
  font-size: 2rem;
  height: 65vh;
}

.features-container {
  text-align: center; /* Center the text of h2 horizontally */
}

.features {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-weight: lighter;
  margin-top: 6rem; /* Add some space between the h2 and the features */
}

.features h3 {
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.features-container h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: lighter;
  color: #c0bdd0;
}

#h2-title {
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
  font-weight: lighter;
  color: #c0bdd0;
}

.feature {
  text-align: center;
  color: #c0bdd0;
  font-weight: lighter;
  width: 30%;
  margin-right: 3rem;
  margin-left: 3rem;
}

.footer {
  background-color: black;
  color: white;
  text-align: center;
  padding: 1rem;
}

.footer p {
  margin: 0;
  font-weight: lighter;
  font-size: calc(10px + 0.5vw);
}

@media only screen and (max-width: 800px) {
    .hero-section, .features-section, .footer {
        padding: 15px;
    }

    p {
      font-size: 12px;
      margin-bottom: 40px;
    }

    .text-recherche h1 {
        font-size: 16px; /* Even smaller for very narrow screens */
    }

    .primary-button, .signup-button {
      font-size: 12px;
      padding: 8px 16px;
    }

    .features {
        flex-direction: column;
    }

    .feature {
        margin: 5px;
    }

    .features-container {
      text-align: center; /* Center the text of h2 horizontally */
    }

    .features-section {
      height: 200vh;
    }

    .video-section {
        display: flex;
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        height: 35vh; /* Take full viewport height */
        background-color: #403250; /* Optional: in case you want a specific background */
    }
}

</style>
