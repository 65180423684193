<template>
  <div class="modal-overlay">
    <div class="modal-content">
      <button class="close-button" @click="$emit('close')">&times;</button>
      <h3 class="modal-title">Sauvegarder la décision</h3>
      <div class="select-container">
        <label for="dossier-select">Sélectionnez un dossier:</label>
        <select v-model="selectedDossierId" id="dossier-select">
          <option disabled value="">-- Sélectionnez un dossier --</option>
          <option v-for="dossier in dossiers" :key="dossier.id" :value="dossier.id">
            {{ dossier.name }}
          </option>
        </select>
      </div>
      <div class="create-container">
        <label for="new-dossier-name">Ou créez un nouveau dossier:</label>
        <div class="input-button-group">
          <input v-model="newDossierName" id="new-dossier-name" placeholder="Nom du nouveau dossier">
          <button class="create-button" @click="createDossier">Créer</button>
        </div>
      </div>
      <div class="actions">
        <button class="save-button" @click="confirmSaveCase">Sauvegarder</button>
      </div>
    </div>
  </div>
</template>

<script>
import { auth, db } from "@/firebase/firebaseConfig";
import { collection, addDoc, setDoc, doc } from 'firebase/firestore';

export default {
  name: 'SaveCaseModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    dossiers: {
      type: Array,
      required: true,
    },
    selectedCase: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDossierId: null,
      newDossierName: '',
    };
  },
  methods: {
    async createDossier() {
        if (!this.newDossierName) {
            alert("Please enter a name for the new dossier.");
            return;
        }
        try {
            const userId = auth.currentUser.uid;
            const dossiersRef = collection(db, 'users', userId, 'dossiers');
            const docRef = await addDoc(dossiersRef, { name: this.newDossierName });
            // Update the selectedDossierId with the newly created dossier's ID
            this.selectedDossierId = docRef.id;
            // Emit the new dossier to the parent component
            this.$emit('dossierCreated', { id: docRef.id, name: this.newDossierName });
            // Now save the case in the newly created dossier
            this.confirmSaveCase();
        } catch (error) {
            console.error('Error creating dossier:', error);
            alert('Error creating new dossier.');
        }
    },
    async confirmSaveCase() {
        if (!this.selectedDossierId) {
            alert('Please select a dossier.');
            return;
        }
        try {
            const userId = auth.currentUser.uid;
            const dossierId = this.selectedDossierId;
            const caseId = this.selectedCase.ID_judi || this.selectedCase.ID;

            const caseDocRef = doc(db, 'users', userId, 'dossiers', dossierId, 'cases', caseId);
            await setDoc(caseDocRef, {
                ID: this.selectedCase.ID,
                title: this.selectedCase.title,
                actual_title: this.selectedCase.actual_title,
                ID_judi: this.selectedCase.ID_judi,
            });

            // Notify the parent component
            this.$emit('caseSaved');

            // Close the modal
            this.$emit('close');

        } catch (error) {
            console.error('Error saving case:', error);
            alert('Error saving the case.');
        }
    },
  },

};
</script>

<style scoped>
/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Darker semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

.modal-content {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  width: 450px;
  max-width: 95%;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #555;
}

.modal-title {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1.5em;
  color: #1f2937;
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 1.3rem;
}

.select-container, .create-container {
  margin-bottom: 20px;
}

.input-button-group {
  display: flex;
  align-items: center;
}

.input-button-group input {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.create-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.create-button:hover {
  background-color: #666;
}

.modal-content label {
  font-weight: 500;
  color: #555;
}

.modal-content select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.actions {
  display: flex;
  justify-content: center;
}

.save-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.save-button:hover {
  background-color: #666;
}
</style>
