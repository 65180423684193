<template>
  <div class="email-verification-alert">
    <h1>Vous devez vérifier votre e-mail avant de pouvoir continuer.</h1>
    <button @click="resendEmail">Renvoyez l'e-mail de vérification</button>
  </div>
</template>

<script>
import { resendVerificationEmail } from '@/firebase/auth'; // Ensure you create this function

export default {
  mounted() {
    window.location.reload();
  },
  methods: {
    resendEmail() {
      resendVerificationEmail().then(() => {
        alert('Verification email has been resent. Please check your inbox.');
      }).catch(error => {
        alert('Error resending the email: ' + error.message);
      });
    }
  }
}
</script>

<style scoped>
.email-verification-alert {
  text-align: center;
  margin-top: 50px;
}
</style>
