<template>
  <div v-if="isVisible" class="modal-overlay" @click.self="closeModal">
    <button class="close-button" @click="closeModal">x</button>
    <div class="modal-content">
      <h2>{{ title }}</h2>
      <div class="modal-text">
        <pre v-html="processedText"></pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: Boolean,
    title: String,
    text: String,
    citation: String,
  },
  data() {
    return {
      processedText: '',
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },

    highlightCitation() {
      // if citation is an emtpy string, return the text as is
      if (!this.citation) {
        this.processedText = this.text;
        return;
      }
      console.log(this.citation.split('[...]')[0]);

      // 1. Remove the first three and last three characters from the citation
      let cleanedCitation = this.citation.split('[...]')[0].trim().slice(3, -3);

      // 2. Escape special characters in the cleaned citation for use in regex
      const escapedCitation = cleanedCitation.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

      // 3. Create a case-insensitive regex that matches the cleaned citation with optional leading and trailing characters
      // Since we've removed three characters from both ends, we need to adjust the regex to match the full citation
      const regex = new RegExp(`.{0,3}${escapedCitation}.{0,3}`, 'i');

      // 4. Check if the citation exists in the text
      const matches = this.text.match(regex);

      if (matches && matches.length > 0) {
        console.log(`Citation found ${matches.length} time(s). Highlighting now...`);

        // 5. Replace each occurrence of the matched pattern with a highlighted span
        this.processedText = this.text.replace(regex, (match) => {
          return `<span style = "background-color: yellow;" id="highlight">${match}</span>`;
        });

        // 6. After updating processedText, scroll to the citation
        this.$nextTick(() => {
          this.scrollToCitation();
        });
      } else {
        console.log('Citation not found in the text.');
        // If no citation is found, just set processedText to original text
        this.processedText = this.text;
      }
    },

    scrollToCitation() {
      this.$nextTick(() => {
        const citationElement = this.$el.querySelector('#highlight');
        console.log('Citation Element:', citationElement);
        if (citationElement) {
          console.log('Scrolling to the highlighted citation...');
          const modalContent = this.$el.querySelector('.modal-content');
          const preElement = modalContent.querySelector('pre');
          console.log('Pre Element:', preElement);

          // const container = document.querySelector('.sommaire-panel');
          // const elementSummary = document.querySelector(`#decision-analysis`);
          // console.log('Element:', elementSummary);

          const elementPosition = citationElement.getBoundingClientRect().top + modalContent.scrollTop - modalContent.getBoundingClientRect().top;
          console.log('Element Position:', elementPosition);
          modalContent.scroll({ top: elementPosition - 20, behavior: 'smooth' });

          // // Calculate the position of the citation relative to the pre element
          // const offsetTop = citationElement.offsetTop;

          // // Scroll so that the citation is centered vertically in the modal
          // const scrollPosition = offsetTop - (modalContent.clientHeight / 2) + (citationElement.clientHeight / 2);
          // preElement.scrollTop = scrollPosition;

          console.log('Scroll completed.');
        } else {
          console.log('No highlighted citation found to scroll to.');
        }
      });
    },
  },
  mounted() {
    console.log('mounted', this.isVisible);
    if (this.isVisible) {
      console.log('Visible');
      this.highlightCitation();
      this.scrollToCitation();
    }
  },
  watch: {
    // isVisible(newVal) {
    //   if (newVal) {
    //     this.highlightCitation();
    //     this.scrollToCitation();
    //   }
    // },
    // text(newText) {
    //   if (this.isVisible) {
    //     this.highlightCitation();
    //     this.scrollToCitation();
    //   }
    // },
    // citation(newCitation) {
    //   if (this.isVisible) {
    //     this.highlightCitation();
    //     this.scrollToCitation();
    //   }
    // },
  },
};
</script>

<style scoped>
/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background-color: #f5efeb;
  padding: 50px;
  border-radius: 8px;
  width: 85%;
  max-height: 85%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
}

/* Title styles */
.modal-content h2 {
  text-align: center; /* Center the title text */
  font-size: 1.4em; /* Increase the size of the title */
  margin-top: 0px; /* Adjust top margin as needed */
  margin-bottom: 3em; /* Space below the title */
}

.modal-text pre {
  font-family: 'Roboto', sans-serif; /* Change the font family */
  white-space: pre-wrap; /* Allows text within <pre> to wrap at natural breaks */
  word-break: break-word; /* Ensures words only break at natural breaking points within the container */
  overflow-wrap: break-word; /* Prefer breaking words at normal word breaks */
  font-size: 0.8em; /* Increase the size of the text */
}

.modal-text pre #highlight {
  background-color: yellow; /* Highlight color for citations */
}


/* Close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 1.5em;
  border: none;
  background: none;
}

#highlight {
  background-color: yellow;
}
</style>
