<template>
  <div class="main-container">
    <div class="mobile-header">
      <div class="logo">
        <img src="@/assets/w-q-white.png" alt="logo" style="border-radius: 20%; width: 50px; height: 50px;">
      </div>
      <button @click="signOut" class="disconnect-button-mobile">Se déconnecter</button>
    </div>
    <div class="previous-searches" >
      <div class="logo">
        <img src="@/assets/w-q-white.png" alt="logo" style="width: 110px; height: 90px;">
      </div>
      <div>
        <h5 style="color: white; font-family: 'Times New Roman', Times, serif; margin-top: 7px"></h5>
      </div>
      <div class="buttons-class">
        <button @click="goToHome" id="sub-but" class="disconnect-button" style="align-self: center; margin-bottom: 2em;">
          Retour à la page principale
        </button>
      </div>
    </div>

    <!-- Div for the Search Form -->
    <div class = "scrollable-container" :style="{ 'flex-direction': showResults ? 'column' : '' }">
      <div class="search-box">
        <h3 class="message-title" v-if="!showResults && !isLoading">Que recherchez-vous aujourd'hui ?</h3>
        <form @submit.prevent="submitSearch" class="search-form">
          <div class="input-container">
            <div
              contenteditable="true"
              @input="updateSearchQuery"
              @paste="handlePaste"
              ref="searchInput"
              :class="{'error-input': searchError}"
              class="search-input"
              data-placeholder="Parlez à Query Juriste"
            ></div>
            <div class="submit-container">
              <button
                type="button"
                class="filter-button"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-filter-circle-fill" viewBox="0 0 16 16">
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M3.5 5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1M5 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m2 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5"/>
                </svg>
              </button>
              <button
                type="submit"
                class="submit-button"
                :disabled="isSubmitDisabled"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  class="bi bi-arrow-up-circle-fill"
                  viewBox="0 0 16 16"
                  :class="{ 'disabled-svg': isSubmitDisabled, 'loading-svg': isLoading }"
                >
                  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
                </svg>
              </button>
            </div>
          </div>
          <div v-if="searchError" class="error-message">{{ errorMessage }}</div>
            <transition name="fade">
              <!-- Display progress messages during loading -->
              <div v-if="isLoading" class="animation-div">
                <div class = 'loading-animation'>
                  {{ searchProgressMessage }}
                </div>
              </div>
              <!-- Display the example button when not loading and search query is empty -->
              <button
                v-else-if="searchQuery === ''"
                @click="submitExampleQuery"
                class="example-button"
              >
                Essayez avec: "Dans le cadre de la Directive européenne sur les droits des actionnaires II (SRD II), quelles sont les obligations légales spécifiques pour une société cotée concernant la divulgation des transactions avec des parties liées dépassant 5 % du chiffre d'affaires net de la société, et comment ces divulgations doivent-elles équilibrer la transparence pour les investisseurs et la nécessité pour la société de protéger des informations commercialement sensibles ?"
              </button>
            </transition>
        </form>
      </div>
      <transition name="fade">
        <div v-if="showResults" class="search-results">
          <!-- Display the response text -->
          <div class="response-content">
            <div v-html="renderMarkdown(reponse)" class="response-markdown-content"></div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { logout } from '@/firebase/auth';
import { marked } from 'marked';
import DOMPurify from 'dompurify'; // Import DOMPurify

export default {

  data() {
    return {
      isSubscribed: false,
      showDeleteModal: false,
      dossierToDelete: null,
      showSaveModal: false,
      selectedCase: null,
      dossiers: [],
      showCreateDossierModal: false,
      selectedDossier: null,
      dossierCases: [],
      showModal: [false, false],
      chambres: [
        { label: 'Commerciale', value: 'commerciale' },
        { label: 'Civile 1', value: 'civile 1' },
        { label: 'Civile 2', value: 'civile 2' },
        { label: 'Civile 3', value: 'civile 3' },
        { label: 'Criminelle', value: 'criminelle' },
        { label: 'Sociale', value: 'sociale' }
      ],
      selectedChambres: [],
      dataCategories: [],
      selectedJuridiction: '',
      selectedLabel: '',
      isDropdownOpen: false,
      isJudiciaireSubmenuOpen: false,
      showChambres: false,
      searchQuery: '',
      lawText: '',
      lawSuggestions: [],
      searchResults: [],
      selectedOptions: [],
      selectedLaws: [],
      products: [],
      options: [
        { value: 'rejet', text: 'Rejet' },
        { value: 'cassation', text: 'Cassation' },
        { value: 'cassation partielle', text: 'Cassation partielle' },
        { value: 'désistement', text: 'Désistement' },
        { value: 'irrecevabilité', text: 'Irrecevabilité' },
        { value: 'non-lieu à statuer', text: 'Non-lieu à statuer' },
        { value: 'rectification erreur matérielle', text: 'Rectification erreur matérielle' }
      ],
      start_date: null,
      end_date: null,
      isLoading: false,
      searchProgressMessage: '',
      reponse: '',
      progressMessages: [
        'Notre IA navigue à travers les différentes décisions...',
        'Notre IA lit les décisions les plus pertinentes...',
        'Presque terminé...'
      ],
      currentProgressIndex: 0,
      currentPage: 1,
      pageSize: 10,
      totalPages: 5,
      caseSummaries: {},
      showDetails: {},
      activeCaseId: null,
      showErrorAnimation: false,
      errorMessage: '',
      searchError: false,
      subscribed: true,
      trialing: false,
      showTextModal: false,
      modalText: '',
      modalTitle: '',
      isArticle: false,
      showDateDropdown: false,
    };
  },

  mounted() {
    // console.log(process.env.stripe_live_key_secret)
    // this.checkUserSubscriptionStatus(); // Call the check subscription function when component mounts
    // this.fetchProducts();
    document.addEventListener('click', this.handleClickOutside);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },

  computed: {
    paginatedResults() {
      const start = (this.currentPage - 1) * this.pageSize;
      return this.searchResults.slice(start, start + this.pageSize);
    },
    displayDateRange() {
      if (!this.start_date && !this.end_date) {
        return 'Filtrer par période - Date'; // Default text when no dates are selected
      }
      let start = this.start_date ? this.start_date : 'Start Date';
      let end = this.end_date ? this.end_date : 'End Date';
      return `${start} - ${end}`;
    },
    showResults() {
      return this.reponse && this.reponse.trim() !== '';
    },
    isSubmitDisabled() {
      return this.isLoading || this.searchQuery.trim() === '';
    },
  },

  methods: {
    toggleDateDropdown() {
      this.showDateDropdown = !this.showDateDropdown;
    },

    updateLinks() {
      const links = document.querySelectorAll('.response-markdown-content a, .markdown-content a');
      links.forEach(link => {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer'); // For security
      });
    },

    updateSearchQuery(event) {
      this.searchQuery = event.target.innerText;
    },

    handlePaste(event) {
      event.preventDefault(); // Prevent the default paste action
      const text = (event.clipboardData || window.clipboardData).getData('text');
      document.execCommand('insertText', false, text);
    },

    startSearchProgress() {
      this.currentProgressIndex = 0;
      this.searchProgressMessage = this.progressMessages[this.currentProgressIndex];
      const interval = setInterval(() => {
        this.currentProgressIndex++;
        if (this.currentProgressIndex >= this.progressMessages.length) {
          clearInterval(interval);
        } else {
          this.searchProgressMessage = this.progressMessages[this.currentProgressIndex];
        }
      }, 3000); // Change message every 3 seconds
    },

    renderMarkdown(markdownText) {
      if (!markdownText) return '';
      // Replace 'stipule' with 'dispose'
      const replacedText = markdownText.replace(/stipule/g, 'dispose');
      const rawHtml = marked(replacedText, {
        gfm: true,
        breaks: true,
        headerIds: true,
        mangle: false,
      });
      return DOMPurify.sanitize(rawHtml);
    },

    submitExampleQuery() {
      const exampleQuery = "Dans le cadre de la Directive européenne sur les droits des actionnaires II (SRD II), quelles sont les obligations légales spécifiques pour une société cotée concernant la divulgation des transactions avec des parties liées dépassant 5 % du chiffre d'affaires net de la société, et comment ces divulgations doivent-elles équilibrer la transparence pour les investisseurs et la nécessité pour la société de protéger des informations commercialement sensibles ?";

      this.searchQuery = exampleQuery;

      // Update the contenteditable div using ref
      this.$refs.searchInput.innerText = exampleQuery;

      this.submitSearch();
    },

    async submitSearch() {
      console.log(this.searchQuery);

      this.searchQuery = this.$refs.searchInput.innerText.trim();

      console.log(this.searchQuery);

      this.searchResults = [];
      this.reponse = '';
      this.currentPage = 1;

      const wordCount = this.searchQuery.trim().split(/\s+/).length;
      if (wordCount < 1 || wordCount > 500) {
        alert('Entrez une description entre 1 et 500 mots.');
        return;
      }

      this.isLoading = true;
      this.startSearchProgress();
      this.searchError = false; // Reset the error state before each search
      this.errorMessage = '';

      const payload = {
        question: this.searchQuery // Adjusted to match the desired payload structure
      };

      const endpoint = "get_response_europe"; // Updated endpoint

      try {
        console.log('Fetching data...');
        const response = await axios.post(`https://api.query-juriste.com/copilot/${endpoint}`, payload);

        if (response.data) {
          console.log('Response Data:', response.data);
          this.reponse = response.data.texte; // Assign the response string to `reponse`
          this.updateLinks();
        } else {
          console.error('Invalid response structure:', response);
          alert('A problem occurred with the response from the server.');
        }

        this.$nextTick(() => {
          this.scrollToFirstResult();
        });
        this.$nextTick(() => {
          this.updateLinks();
        });
      } catch (error) {
        if (error.response && error.response.status === 429) {
          this.searchError = true;
          this.errorMessage = 'Vous avez dépassé le nombre de requêtes autorisées sans abonnement. Veuillez vous abonner pour continuer à utiliser le service.';
          this.checkUserSubscriptionStatus();
        } else {
          console.error('Error while fetching data:', error);
          this.errorMessage = 'Erreur lors de la récupération des données.';
          this.searchError = true;
        }
      } finally {
        this.isLoading = false; // Stop loading
      }
    },

    async scrollToFirstResult() {
      await this.$nextTick();
      // const element = this.$refs.firstResult;
      const container = document.querySelector('.scrollable-container');
      const firstResultElement = container.querySelector('.search-results');
      console.log('First Result:', firstResultElement);
      if (firstResultElement) {
        const elementPosition = firstResultElement.getBoundingClientRect().top + container.scrollTop - container.getBoundingClientRect().top;
        container.scroll({ top: elementPosition - 50, behavior: 'smooth' });
      }
    },

    async signOut() {
      try {
        await logout();
        this.$router.replace({ path: '/' });
      } catch (error) {
        alert(error.message); // Consider using a more user-friendly error handling approach
      }
    },

    goToHome() {
      this.$router.push({ path: '/' });
    },

    changePage(step) {
      const nextPage = this.currentPage + step;
      if (nextPage > 0 && nextPage <= this.totalPages) {
        this.currentPage = nextPage;
        // Optionally, scroll to top of the results or perform other UI adjustments
      }
    },
  },
};
</script>

<style scoped>

.decisions-presentations {
  display: flex;
  justify-content: center;
}

.decisions-pertinentes-title {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin: 25px;
}

.response-content {
  padding: 1em;
  border-radius: 20px;
  margin-bottom: 1em;
}

.response-markdown-content >>> h3,
.response-markdown-content >>> h4,
.response-markdown-content >>> h5,
.response-markdown-content >>> h6 {
  font-weight: bold;
  margin-top: 1em !important;
  margin-bottom: 1em !important;
  font-family: 'Roboto', sans-serif !important;
}

.response-markdown-content >>> h3 {
  font-size: 1.5em;
}

.response-markdown-content >>> strong {
  font-weight: bold;
}

.response-markdown-content >>> p {
  font-family: 'Roboto', sans-serif !important;
  margin-bottom: 2em;
}

.response-markdown-content >>> p {
  font-size: 1em;
}

.response-markdown-content >>> li {
  font-family: 'Roboto', sans-serif !important;
  font-size: 1em;
}

.response-markdown-content >>> a {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}

.message-title {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.submit-container {
  display: flex;
  justify-content: space-between; /* Aligns items to the right */
}

.filter-button {
  margin-left: 10px;
}

.input-container {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 5px;
  align-items: center;
}

.search-input {
  position: relative;
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 8px;
  background-color: transparent;
  word-break: break-word;
  white-space: pre-wrap;
  min-height: 40px; /* Ensures adequate height for input */
  max-width: 100%; /* Prevents the input from overflowing */
}

.search-input::before {
  content: attr(data-placeholder);
  position: absolute;
  top: 10px;
  left: 10px;
  color: #aaa;
  pointer-events: none;
  transition: opacity 0.2s;
  white-space: nowrap; /* Prevents placeholder from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-input:empty::before {
  opacity: 1;
}

.search-input:not(:empty)::before {
  opacity: 0;
}

.disabled-svg {
  color: grey; /* Changes the SVG color to grey */
  opacity: 0.6; /* Reduces opacity to indicate disabled state */
  transition: color 0.3s, opacity 0.3s;
}

/* Existing .submit-button styles */
.submit-button {
  background-color: #f4f4f4; /* Matches container background */
  color: black;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
}

.animation-div {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.submit-button:disabled {
  cursor: not-allowed; /* Changes cursor to indicate disabled state */
}

/* Remove previous .submit-button.disabled-button styles if any */
.submit-button.disabled-button {
  /* Remove or comment out these styles */
  /* background-color: #ccc;
     cursor: not-allowed;
     opacity: 0.6;
     pointer-events: none; */
}

/* Optional: Adjust hover state to ensure no hover effects when disabled */
.submit-button:disabled:hover {
  background-color: #f4f4f4; /* Maintain original background color */
}

/* Existing .submit-button.loading styles */
.submit-button.loading {
  cursor: wait;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.example-button {
  background-color: white;
  color: black;
  border: 0.5px solid lightgrey; /* Thinner and lighter border */
  padding: 10px 15px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1); /* Softer shadow */
}

.example-button:hover {
  background-color: lightgray;
}

.date-selector-container {
  position: relative;
  display: inline-block;
  width: 50%;
}

.date-selector {
  margin-bottom: 5px;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-date-display {
  font-size: 16px;
  color: #333;
}

.date-dropdown {
  position: absolute;
  width: calc(100% - 20px); /* Subtract padding from total width */
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  border-radius: 4px;
  top: 100%; /* Position right below the selector */
  left: 0; /* Align left edges */
  margin-top: 5px; /* Space between selector and dropdown */
  z-index: 100;
}

.form-control {
  display: block;
  width: 100%; /* Full width */
  padding: 0.6em 0.8em; /* Vertical and horizontal padding */
  border: 1px solid #ccc; /* Border to match your style */
  border-radius: 4px; /* Rounded corners */
  background-color: #f4f4f4; /* Background color */
  line-height: 1.25; /* Adjust line height to ensure vertical centering */
  height: auto; /* Let the height grow with content and padding */
  appearance: none; /* Remove default system styling */
}

.date-input-container {
  padding: 10px;
}


.dossier-button {
  background-color: black;
  color: white;
}

.create-dossier-button {
  width: 100%;
  background-color: #f4f4f4;
  color: #1c1c1a;
  padding: 10px;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

.create-dossier-button:hover {
  background-color: #e0e0e0;
}

.dossiers-list {
  height: 60%;
  width: 80%;
  overflow-y: auto;
}

.dossiers-list h5 {
  margin-top: 20px;
}

.dossier-button {
  width: 100%;
  color: white;
  background-color: #1c1c1a;
  padding: 5px;
  border: none;
  border-radius: 4px;
  text-align: left;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s;
  white-space: nowrap; /* Prevent text from wrapping to new line */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for truncated text */
}

.dossier-button:hover {
  background-color: grey;
}

.button-bookmark {
  width: 7%;
}

#title-bookmark {
  display: flex;
  justify-content: space-between;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 50%;
}

.loading-animation {
  color: transparent; /* Make text color transparent when loading */
  background: linear-gradient(to left, lightgray 50%, black 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  animation: slide 3s linear infinite;
  cursor: default; /* Change cursor to default to indicate it's not clickable */
  pointer-events: none; /* Prevents interactions while loading */
}

@keyframes slide {
  to {
    background-position: -100%;
  }
}

.custom-dropdown-menu {
  position: absolute;
  background-color: white;
  width: 100%;
  border: 1px solid #ccc;
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
}

.dropdown-item {
  padding: 8px 12px;
  align-items: center;
  display: flex;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.submenu-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.arrow-icon {
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
}

.juridiction-text {
  flex-grow: 1;
}

.placeholder-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.chambre-item {
  padding-left: 32px;
  display: flex;
  align-items: center;
}

input[type='checkbox'] {
  margin-right: 8px;
}

.contested-case-card {
  margin-top: 30px;
  display: flex; /* Enables flexbox layout */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  text-align: center; /* Aligns text to the center */
  height: 100%; /* Ensures that flexbox centers content vertically */
}

.previous-searches .scrollable-checkbox-container {
  margin-top: 45px; /* Space between logo and checkboxes */
  background-color: #f8f9fa; /* Light grey background */
  max-height: 160px; /* Maximum height before scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%; /* Full width of the container */
  padding: 10px; /* Padding inside the container */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.scrollable-checkbox-container {
  padding-top: 5px;
}

.previous-searches .form-check-label {
  color: #333; /* Dark text for better visibility on light grey */
  font-size: 0.9rem; /* Appropriate font size */
  margin-left: 0.5rem; /* Space between checkbox and label */
}

.previous-searches .form-check-input {
  vertical-align: middle;
  margin-top: 0.3rem; /* Adjust vertical alignment */
}

.previous-searches .form-check {
  margin-bottom: 10px; /* Spacing between checkboxes */
}

.buttons-class {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 80%;
}

.error-input {
  box-shadow: 0 0 8px rgba(255, 0, 0, 0.6); /* Red border for error inputs */
}

.error-message {
  color: lightcoral; /* Red text for error message */
  font-size: 17px; /* Font size for error message */
  margin-top: 25px; /* Space between input and error message */
  text-align: center; /* Center the error message */
}

.fade-enter-active{
  transition: clip-path 6s ease-out, opacity 6s;
  clip-path: inset(0 0 0 0); /* Ends with the full element visible */
  opacity: 1;
}

.fade-enter {
  opacity: 0;
  clip-path: inset(0 0 100% 0); /* Start with the element fully clipped at the bottom */
}

.fade-leave-active {
  transition: clip-path 0.3s ease-out, opacity 0.3s;
  clip-path: inset(0 0 0 0); /* Ends with the full element visible */
  opacity: 1;
}

.fade-leave-to {
  opacity: 0;
  clip-path: inset(100% 0 0 0); /* Start with the element fully clipped at the bottom */
}

.example-query button {
  background-color: #f4f4f4;
  color: black;
  border: none;
  padding: 10px 15px;
  border-radius: 50px; /* Increased to make the button very rounded */
  cursor: pointer;
  font-size: 12px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.example-query button:hover {
  background-color: lightgray;
}

.small-arrow {
  font-size: 10px;  /* Set a smaller font size for the arrow */
  vertical-align: 1px;
}

.summary-toggle-button {
  font-size: 15px;  /* Slightly larger font size */
  margin-top: 12px; /* Margin top */
}

#law-input-container {
  width: 90%;
  margin-top: 20px;
}

.case-details p {
    margin-bottom: 10px;  /* Adds space below each paragraph */
}

/* Optionally, you can add top margin to space it from any preceding content */
.case-details {
    margin-top: 20px;
}

.form-chambre {
  display: block;  /* Default display */
  width: 50%; /* Full width */
  padding: 0.6em 0.8em; /* Vertical and horizontal padding */
  border: 1px solid #ccc; /* Border to match your style */
  border-radius: 4px; /* Rounded corners */
  background-color: #f4f4f4; /* Background color */
  line-height: 1.25; /* Adjust line height to ensure vertical centering */
  height: auto; /* Let the height grow with content and padding */
  appearance: none; /* Remove default system styling */
  font-weight: 500;
  margin-bottom: 5px;

}

.form-chambre {
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%2212%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Cpath%20d%3D%22M0%206l6%206%206-6z%22%20fill%3D%22%23007BFF%22%20fill-rule%3D%22evenodd%22%2F%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

/* Adjust the focus state to improve usability */
.form-chambre:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6);
}


.selected-law-tag {
  background-color: black; /* Primary blue, can adjust to match theme */
  color: white;
  padding: 4px 8px;
  font-size: 0.75rem; /* Smaller font size for compact tags */
  border-radius: 12px; /* Rounded corners for a smoother look */
  margin: 2px; /* Smaller margin for tight layout */
  display: flex;
  align-items: center;
  height: 20px; /* Fixed height for uniformity */
}

.selected-law-tag span {
  margin-left: 6px;
  cursor: pointer;
  font-size: 0.85rem; /* Slightly larger to make it easier to click */
}

.cursor-pointer {
  cursor: pointer;
}

.mobile-header {
  display: none;
}

.small-card {
  font-size: 0.8rem; /* Smaller font size */
  padding: 0.25rem 0.5rem; /* Smaller padding for more compact look */
}

.previous-searches {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  height: 100%;
  border-right: 1px solid #ccc;
  padding-top: 20px;
  overflow-y: auto;
  background-color: #1c1c1a;
  position: sticky;
  top: 0; /* This line ensures the div sticks to the top of the viewport */
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

.scrollable-container {
  display: flex;
  align-items: center;
  width: 80%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.result-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align items to the start of the container */
}

.result-item {
  padding: 20px;
  margin-bottom: 15px; /* Increase space between items */
}


.result-text {
  font-weight: bold; /* Make the result text bold */
}

.result-link {
  color: #007bff; /* Blue link color */
  text-decoration: none; /* Remove underline */
}

.result-link:hover {
  text-decoration: underline; /* Underline on hover */
}

#titles {
  align-self: center; /* Larger font size for titles */
  font-size: 0.8rem;
  margin: 6px;
}

.search-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.input-container {
}

.search-input {
  width: 150vw; /* Adjust the width to 70% and leave space for the margin */
  padding: 8px;
  border-radius: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none; /* Prevent resizing */
}

.solution-select {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  overflow-y: auto; /* Allow scrolling if there are many options */
}

.search-box {
  height: 60vh;
  margin: 0 auto;
  width: 90%;
  padding: 20px;
}

.search-button {
  background-color: #1c1c1a; /* Dark green background */
  color: white; /* White text */
  padding: 12px 20px; /* Padding for a larger button */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Hand cursor on hover */
  font-size: 16px; /* Larger font size */
  margin-top: 5px; /* Margin at the top */
  transition: background-color 0.5s ease;
  position: relative; /* Needed for the gradient effect positioning */
  overflow: hidden; /* Keeps the gradient within the button boundaries */
}

.search-button:hover {
  background-color: lightgray; /* Lighter gray on hover */
}

.search-button.loading {
  color: transparent; /* Make text color transparent when loading */
  background: linear-gradient(to left, lightgray 50%, black 50%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 100%;
  background-position: 100%;
  animation: slide 3s linear infinite;
  cursor: default; /* Change cursor to default to indicate it's not clickable */
  pointer-events: none; /* Prevents interactions while loading */
}

@keyframes slide {
  to {
    background-position: -100%;
  }
}

.disconnect-button {
  background-color: #f4f4f4;
  font-size: 0.9rem;
  color: #1c1c1a;
  padding: 15px 20px; /* Padding */
  border: none; /* No border */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Hand cursor on hover */
  align-self: center; /* Center button horizontally */
  margin-bottom: 5px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  width: 100%;
}

#sub-but:hover {
  background-color: white; /* Darker shade of grey on hover */
  transform: scale(1.1); /* Increase size on hover */
  box-shadow: 0 0 10px 0 white; /* Add white shadow on hover */
}

#disco {
  background-color: #1c1c1a;
  color: #f4f4f4;
}

#disco:hover {
  background-color: grey; /* Darker shade of red on hover */
}

#gerer {
  background-color: #1c1c1a;
  color: #f4f4f4;
}

#gerer:hover {
  background-color: grey; /* Darker shade of red on hover */
}

.search-results {
  width: 90%;
  margin-top: 30px;
  font-family: 'Roboto', sans-serif;
  border-radius: 4px; /* Rounded corners */
  padding: 10px; /* Padding inside the results box */
  justify-content: center;
  display: flex;
}

.result-item {
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #ccc;
  background-color: #fff; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners for a modern look */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  margin-bottom: 10px; /* Space between items */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for hover effects */
}

.result-item:hover {
  transform: translateY(-4px); /* Slight lift effect */
  box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Enhanced shadow on hover */
}

.result-title {
  margin: 0 0 8px 0; /* Adjust spacing around the title */
  width: 93%;
  font-size: 1.1rem; /* Larger font size for titles */
  color: #333; /* Dark color for contrast */
}

.result-link {
  color: #007bff; /* Keep the blue link color */
  text-decoration: none; /* No underline */
  font-weight: bold; /* Make links bold for emphasis */
}

.result-link:hover {
  text-decoration: underline; /* Underline on hover for feedback */
}

.checkboxes-and-date-container {
    overflow-y: auto;
    border-radius: 10%;
    width: 50%;
  }

.solution-select {
  width: 100%;
  margin-top: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.date-range-selection {
  padding: 8px;
}

.form-control {
}

li {
  margin-bottom: 15px;
  padding: 10px;
}

h3 {
  margin-top: 0;
  text-align: center;
  color: #333;
}

#input-2 {
  margin-top: 5px;
}

.list-enter-active, .list-leave-active {
  transition: all 0.5s ease;
}

.list-enter, .list-leave-to /* .list-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.pagination-controls button {
  padding: 5px 15px;
  margin: 0 10px;
  background-color: #007bff; /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-controls button:hover {
  background-color: #0056b3; /* Darken button on hover */
}

.pagination-controls button:disabled {
  background-color: #cccccc; /* Light gray for disabled state */
  cursor: not-allowed;
}

.pagination-controls span {
  color: #333; /* Dark gray color for text */
  font-weight: bold;
}

.main-container {
  background-color: white; /* Light background for visibility */
  display: flex;
  height: 100%;
}

.select-container {
  margin-top: 20px; /* Space below the select dropdown */
  display: flex;
}

.form-control {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Ensure it matches the input fields in width */
}




@media (max-width: 768px) {
  .previous-searches {
    display: none;
  }

  .mobile-header {
    display: flex; /* Enable display for mobile header */
    width:100% ;
    justify-content: space-between; /* Space between logo and button */
    align-items: center; /* Center items vertically */
    width: 100%; /* Full width of the viewport */
    padding: 10px 20px; /* Padding for breathing space */
    background-color: #f5f5f5; /* Light background for visibility */
  }

  .disconnect-button-mobile {
    padding: 8px 16px;
    background-color: black; /* Consistent with other button styles */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .scrollable-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    overflow-y: hidden;
    scroll-behavior: smooth;
  }

  .scrollable-container {
    width: 100%; /* Full width to utilize the screen space */
    padding: 20px; /* Adjust padding for smaller screens */
    flex-direction: column;
    overflow-y: visible; /* Adjust overflow properties if needed */
    height: 150vh;
  }

  .input-container {
  }

  .checkboxes-and-date-container {
    width: 100%; /* Full width to accommodate smaller screens */
    display: flex;
    flex-direction: column; /* Stack checkbox and date inputs vertically */
  }

  .search-input {
    width: 100%; /* Adjust width for mobile */
    min-height: 100px; /* Adjust the height to be more accommodating for text input */
  }

  .search-results {
    margin-top: 20px; /* Reduce margin top for tighter mobile layout */
  }

  .arrow-up::before {
    content: '\25B2'; /* Unicode upwards arrow */
  }
  .arrow-down::before {
    content: '\25BC'; /* Unicode downwards arrow */
  }

  .main-container {
    display: block;
    height: 100%;
  }

  #check-container {
    width: 65vw;
    margin-top: 10px;
    display: none;
  }

  #date-container {
    width: 100%;
  }

  .search-box {
    height: 100%;
    margin: 0 auto;
    width: 90%;
    padding: 20px;
  }

  .search-button {
    margin-top: 10px;
  }

}


/* Add the rest of your CSS here, similar to how .search-input and .search-box were added. */
</style>
