// router/index.js
import Vue from "vue";
import Router from "vue-router";
import HomePage from "@/components/HomePage.vue";
import LoginPage from "@/components/LoginPage.vue";
import SigninPage from "@/components/SigninPage.vue";
import FirstPage from "@/components/FirstPage.vue";
import antoineFinalPage from "@/components/antoineFinalPage.vue"; // Ensure path is correct
import About from "@/components/about.vue"; // Renamed to PascalCase for consistency
import TestPage from "@/components/testPage.vue"; // Renamed to PascalCase
import EmailVerificationAlert from "@/components/EmailVerificationAlert.vue";
import EmailVerified from "@/components/VerifiedEmail.vue";
import SuccessPage from "@/components/SuccessPage.vue";
import CancelPage from "@/components/CancelPage.vue";
import SubscriptionPage from "@/components/subscriptionPage.vue"; // Renamed to PascalCase
import UsageGuide from "@/components/usageGuide.vue"; // Renamed to PascalCase
import MentionsPage from "@/components/mentionsPage.vue"; // Renamed to PascalCase
import { getAuth } from "firebase/auth";
import DashboardPage from "@/components/dashboardPage.vue"; // Ensure consistent casing
import ConditionsGeneralePage from "@/components/conditionsGeneralePage.vue"; // Renamed to PascalCase
import { app } from "@/firebase/firebaseConfig";
import SignupWithTokenPage from "@/components/SignupWithTokenPage.vue";
import ContactPage from "@/components/contactPage.vue"; // Renamed to PascalCase
import EuropePage from "@/components/EuropePage.vue";
import DossierPage from '@/components/DossierPage.vue'; // Ensure path is correct
import PresentationPage from "@/components/PresentationPage.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    // Redirect root path based on authentication status
    {
      path: '/',
      redirect: () => {
        const auth = getAuth(app);
        const user = auth.currentUser;
        if (user && user.emailVerified) {
          return { name: 'dashboard' };
        } else {
          return { name: 'about' };
        }
      }
    },
    {
      path: "/antoineFinalPage",
      name: "antoineFinal",
      component: antoineFinalPage
    },
    {
      path: "/dashboardPage",
      name: "dashboard",
      component: DashboardPage,
      meta: { requiresAuth: true } // Protect DashboardPage
    },
    {
      path: "/loginPage",
      name: "login",
      component: LoginPage
    },
    {
      path: "/conditionsGeneralePage",
      name: "conditionsGenerale",
      component: ConditionsGeneralePage
    },
    {
      path: "/mentionsPage",
      name: "mentions",
      component: MentionsPage
    },
    {
      path: "/testPage",
      name: "test",
      component: TestPage
    },
    {
      path: "/VerifiedEmail",
      name: "verifiedEmail",
      component: EmailVerified
    },
    {
      path: "/about",
      name: "about",
      component: About
    },
    {
      path: '/dossier/:dossierId',
      name: 'DossierPage',
      component: DossierPage,
      meta: { requiresAuth: true } // Protect DossierPage
    },
    {
      path: "/PresentationPage",
      name: "presentation",
      component: PresentationPage
    },
    {
      path: "/EuropePage",
      name: "Europe",
      component: EuropePage
    },
    {
      path: "/contactPage",
      name: "contact",
      component: ContactPage
    },
    {
      path: "/homePage",
      name: "home",
      component: HomePage,
      meta: { requiresAuth: true } // Protect HomePage
    },
    {
      path: "/signinPage",
      name: "signin",
      component: SigninPage
    },
    {
      path: "/signupWithTokenPage",
      name: "signuptoken",
      component: SignupWithTokenPage
    },
    {
      path: "/firstPage",
      name: "first",
      component: FirstPage
    },
    {
      path: "/usageGuide",
      name: "usageGuide",
      component: UsageGuide
    },
    {
      path: "/verify-email",
      name: "verifyEmail",
      component: EmailVerificationAlert
    },
    {
      path: "/subscriptionPage",
      name: "subscription",
      component: SubscriptionPage
    },
    {
      path: "/success",
      name: "success",
      component: SuccessPage
    },
    {
      path: "/cancel",
      name: "cancel",
      component: CancelPage
    }
  ]
});

const auth = getAuth(app);

// Initialize authCheckPromise to ensure auth state is determined before routing
const authCheckPromise = new Promise((resolve, reject) => {
  auth.onAuthStateChanged(user => {
    resolve(user);
  }, reject);
});

router.beforeEach((to, from, next) => {
  authCheckPromise.then(() => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const user = auth.currentUser;
    const isAuthenticated = !!user;
    const userVerified = user ? user.emailVerified : false;

    console.log(`Navigating to ${to.path} from ${from.path}`);
    console.log(`isAuthenticated: ${isAuthenticated}, userVerified: ${userVerified}`);

    // **New Condition:** Redirect verified users away from the /verify-email page
    if (to.path === '/verify-email' && isAuthenticated && userVerified) {
      console.log('User is verified and authenticated. Redirecting from /verify-email to /dashboardPage.');
      return next('/dashboardPage');
    }

    if (requiresAuth && !isAuthenticated) {
      // Route requires authentication but user is not authenticated
      console.log('Route requires auth but user is not authenticated. Redirecting to /loginPage');
      return next("/loginPage");
    } else if (requiresAuth && isAuthenticated && !userVerified) {
      // Route requires authentication, user is authenticated but email not verified
      console.log('User is authenticated but email not verified.');

      // Check if the navigation is from /signupWithTokenPage to /homePage
      const isFromSignupWithTokenToHome =
        from.path === '/signupWithTokenPage' && to.path === '/homePage';

      if (isFromSignupWithTokenToHome) {
        console.log('Allowing navigation from /signupWithTokenPage to /homePage without email verification.');
        return next(); // Allow navigation
      } else {
        // Define allowed paths when email is not verified
        const allowedPaths = ['/', '/verify-email', '/signupWithTokenPage'];

        if (allowedPaths.includes(to.path)) {
          console.log(`Accessing allowed path: ${to.path}`);
          return next(); // Allow access to allowed paths
        } else {
          console.log('Redirecting to /verify-email');
          return next("/verify-email"); // Redirect to verify email
        }
      }
    } else if (
      !requiresAuth &&
      isAuthenticated &&
      userVerified &&
      (to.path === "/loginPage" || to.path === "/signinPage" || to.path === "/" || to.path === "/signupWithTokenPage" || to.path === "/verify-email" || to.path === "/testPage")
    ) {
      // Authenticated and verified user trying to access login/signin/firstPage
      console.log('Authenticated and verified user trying to access auth pages. Redirecting to /dashboardPage.');
      return next("/dashboardPage");
    } else {
      // For all other cases, proceed as normal
      console.log(`Proceeding to ${to.path}`);
      return next();
    }
  }).catch(error => {
    console.error('Error in authCheckPromise:', error);
    return next("/loginPage"); // Redirect to login in case of error
  });
});

export default router;
