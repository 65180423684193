<template>
  <div v-if="visible" class="modal-container-sub">
    <button class="close-button" @click="closeModal">×</button>
      <div class="modal-overlay">
        <div class="modal-content-sub">
          <div class="logo">
            <img class="img-logo" src="../assets/logo-query-juriste.png" alt="logo" style="width: 110px; height: 90px;">
          </div>
          <div class="text-essai">
            <p class="product-title">Problème de droit complexe?</p>
            <p class="small-font">Demandez à <em>Copilote</em>, l'agent de recherche intelligent.</p>
          </div>
          <div class = "button-container">
            <button class="contact-button" id="copilot-button" @click="goToDossier">Interroger copilot</button>
            <button class="contact-button" id="moteur-button" @click="stayOnSearch">Continuer sur le moteur de recherche</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  props: ['visible'],
  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit('close'); // Emitting an event
    },
    handleCloseModal(index) {
      this.$set(this.visible, index, false); // Vue.set for reactivity in case needed
    },
    stayOnSearch() {
      this.$emit('stay-on-search');
    },
    goToDossier() {
      this.$emit('go-to-dossier');
    },
  }
};
</script>

<style scoped>

.contact-button {
  background-color: black;
  margin: 0.5em;
  color: white;
  width: 35%;
  padding: 0.5em;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  transition: background-color 0.3s ease-in-out;
  outline: none;
}

.contact-button:hover {
  background-color: lightgray;
  opacity: 0.9;
}

.custom-subscribe-button {
  background-color: black;
  color: white;
  padding: 1rem 3rem; /* Adjust padding as needed */
  border-radius: 0.375rem; /* This is equivalent to 'rounded-md' */
  font-size: 1rem; /* Smaller font size */
  transition: background-color 0.3s ease-in-out; /* Smooth transition for hover effects */
  outline: none; /* Removes outline to match 'focus:outline-none' */
  /* Focus Ring */
}

.custom-subscribe-button:hover:not(:disabled) {
  background-color: lightgray; /* Hover effect */
  opacity: 0.9;
}

.custom-subscribe-button:disabled {
  background-color: gray; /* Disabled state */
  cursor: not-allowed;
}

.custom-subscribe-button:focus {
  /* Focus state, replicating 'focus:ring-offset-2' effect */
  box-shadow: 0 0 0 2px white, 0 0 0 4px rgba(59, 130, 246, 0.5);
}

.modal-container-sub {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 10px;
  font-family: 'Helvetica', sans-serif;
}

.modal-overlay {
  flex: 1; /* Each modal takes equal width */
  display: flex;
  justify-content: center;
  height: 80%;
}

.modal-content-sub {
  margin: 10px;
  font-family: 'Helvetica', sans-serif;
  background: #f6f6f6;
  padding: 20px;
  border-radius: 8px;
  height: 65%;
  width: 45%; /* Adjusted for visibility within flex container */
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0; /* Remove space below logo */
}

.small-font {
  text-align: center;
  font-size: 0.875rem; /* Adjust this value as needed */
}

.modal-content.small-font {
  font-size: 0.95em; /* Slightly smaller text overall in the modal */
}

.price-text.small-font {
  font-size: 40px; /* Smaller price text for 'Essentiel' */
}

.product-title.small-font {
  font-size: 1.75rem; /* Smaller product title */
}

.price-container {
  text-align: center;
  margin-top: 0; /* Remove space above price container */
}

.price-text {
  font-size: 45px;
  font-weight: bold;
  color: black;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.product-title {
  font-family: 'Roboto', sans-serif; /* Change font family */
  font-size: 1.25rem; /* Equivalent to 3xl */
  text-align: center;
  color: #1f2937; /* Equivalent to gray-900 */
}

.button-container {
  margin: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: gray;
  transition: color 0.2s;
}

.close-button:hover {
  color: black;
}

.subscribe-button, .close-button {
  transition: background-color 0.2s;
  font-weight: bold;
  cursor: pointer;
}

.subscribe-button:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.subscribe-button:hover:not(:disabled) {
  background-color: lightgray !important; /* Changed to light gray */
  opacity: 0.9;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: gray;
  transition: color 0.2s;
}

.close-button:hover {
  color: black;
}

#moteur-button {
  font-size: 0.6em;
  background-color: rgb(82, 79, 79);
}

#copilot-button {
  width: 50%;
}

</style>
