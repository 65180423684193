import { app, db } from '@/firebase/firebaseConfig';
import Router from 'vue-router';
import axios from 'axios';
import { doc, updateDoc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendEmailVerification, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
// import router from '@/router';

// const authentication = app.auth(); // authentication information
// Sign up

const deleteUserFromLemListCampaign = async (userEmail) => {
  const campaignId = process.env.VUE_APP_LEMLIST_CAMPAIGN_ID;
  const apiKey = process.env.VUE_APP_LEMLIST_API_KEY;

  try {
    await axios.delete(
      `https://api.lemlist.com/campaigns/${campaignId}/leads/${userEmail}`,
      {
        headers: {
          'Authorization': `Bearer ${apiKey}`
        }
      }
    );
    console.log(`User with email ${userEmail} has been removed from LemList campaign ${campaignId}.`);
  } catch (error) {
    console.error('Error removing user from LemList campaign:', error.response ? error.response.data : error.message);
    // We're not throwing the error here to prevent it from blocking the sign-in process
  }
};

const auth = getAuth(app);
export const signUp = async (email, password, isEligibleForTrial = false) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    // Mark eligibility in the Firestore
    const userId = userCredential.user.uid;
    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, {
      email: email,
      eligibleForTrial: isEligibleForTrial,
      // other initial user properties
    }, { merge: true });
    if (!isEligibleForTrial) {
      sendEmailVerification(auth.currentUser)
        .then(() => {
          console.log("Verification email sent successfully.");
        })
        .catch((error) => {
          console.error("Error sending verification email:", error);
        });
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Sign in
export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    if (userCredential.user.emailVerified) {
      console.log("Email is verified");
      console.log(userCredential.user);

      // Delete user from LemList campaign
      await deleteUserFromLemListCampaign(email);

      // router.push("/homePage"); // Redirect to home page
    } else {
      Router.push("/verify-email");
    }
  } catch (error) {
    console.error('Error during sign-in:', error);
    throw error;
  }
};

// Sign out
export const logout = () => {
  signOut(auth).then(() => {
    // Sign-out successful.
    console.log("User logged out successfully");
    // Redirect to login page or update UI accordingly
  }).catch((error) => {
    // An error happened.
    console.error("Error logging out:", error);
  });
};

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    console.log(user);

    // Delete user from LemList campaign
    await deleteUserFromLemListCampaign(user.email);

    // Handle navigation or UI response after successful sign in.
  } catch (error) {
    console.error('Error during Google Sign-In:', error);
    throw error;
  }
};

auth.onAuthStateChanged(user => {
  if (user) {
    if (user.emailVerified) {
      console.log("Email is verified");
      // Redirect the user or update UI
    } else {
      console.log("Email is not verified");
      // Remind the user to check their email for the verification link
    }
  }
});

// Function to resend verification email
export const resendVerificationEmail = () => {
  if (auth.currentUser) {
    return sendEmailVerification(auth.currentUser);
  } else {
    return Promise.reject(new Error("No user logged in"));
  }
};

export const updateDocument = async (token) => {
  const inviteRef = doc(db, 'invites', token);

  // First, check if the document exists
  const inviteDoc = await getDoc(inviteRef);

  if (inviteDoc.exists()) {
    // If the invite exists, mark it as used
    await updateDoc(inviteRef, { used: true });
  } else {
    // Handle the case where the invite does not exist
    throw new Error("Invite token does not exist or is invalid.");
  }
};
